import { ConversationResponseModel } from '@capturi/api-conversations'
import { useUsers } from '@capturi/stores'
import { HStack } from '@chakra-ui/react'
import React from 'react'
import { Hit } from '../Audio/types'
import CommentsSection from './Comments/CommentsSection'
import StatisticsView from './Statistics/StatisticsView'

type CoachingView = {
  conversation: ConversationResponseModel
  agentName?: string
  setHits: (hits: Hit[]) => void
}

const CoachingView: React.FC<CoachingView> = ({ conversation, setHits }) => {
  const { getUserByUid } = useUsers()

  return (
    <HStack align="start" gap="6">
      <StatisticsView
        conversation={conversation}
        agentName={getUserByUid(conversation.userUid).name}
        key={getUserByUid(conversation.userUid).profileImage?.key}
      />
      <CommentsSection conversation={conversation} setHits={setHits} />
    </HStack>
  )
}

export default CoachingView
