import {
  Comment,
  CommentsResponseModel,
  commentsAPI,
} from '@capturi/api-comments'
import { formatTime, useAudioContext } from '@capturi/audio'
import { Button, ButtonProps, Join, PlayButton } from '@capturi/ui-components'
import {
  Avatar,
  Box,
  Divider,
  Flex,
  HStack,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { ReactElement, useEffect, useMemo } from 'react'
import { MdComment, MdForward, MdReply } from 'react-icons/md'

import analytics from '@capturi/analytics'
import { ConversationResponseModel } from '@capturi/api-conversations'
import { useAPI } from '@capturi/api-utils'
import { useCurrentUser } from '@capturi/core'
import { generateImageUrl } from '@capturi/request'
import { useUsers } from '@capturi/stores'
import { useModal } from '@capturi/use-modal'
import { i18n } from '@lingui/core'
import RelativeTimeFormat from 'components/RelativeTime'
import { useNotificationsContext } from 'features/notifications'
import { Hit } from '../../Audio/types'
import CreateCommentModal from './CreateCommentModal'

const TooltipDateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

type CommentsViewProps = {
  conversation: ConversationResponseModel
  agentName?: string
  setHits: (hits: Hit[]) => void
}

const AddCommentButton: React.FC<Omit<ButtonProps, 'children'>> = (props) => (
  <Button size="sm" pl={4} leftIcon={<MdComment />} {...props}>
    <Trans>Add comment</Trans>
  </Button>
)

const CommentsView: React.FC<CommentsViewProps> = ({
  conversation,
  setHits,
}) => {
  const isPlayable = !conversation.deleted
  const { getTime, isPlaying, play, pause } = useAudioContext(
    `/playback/audio/${conversation.uid}`,
    {
      rollbackSeconds: 3,
    },
  )
  const currentUser = useCurrentUser()
  const [playingTrackId, setPlayingTrackId] = React.useState<string>('')
  const { markCommentNotificationsAsRead } = useNotificationsContext()
  const { getUserByUid } = useUsers()

  const [openCommentsModal] = useModal(CreateCommentModal)

  const { data, mutate } = useAPI<CommentsResponseModel>(
    commentsAPI.getComments(conversation.uid),
  )
  const comments = useMemo(
    () =>
      (data?.comments || []).sort(
        (a, b) => b.created.getTime() - a.created.getTime(),
      ),
    [data],
  )

  useEffect(() => {
    const hits: Hit[] = comments.map((comment) => ({
      id: comment.uid,
      title: comment.text,
      timestamp: comment.when,
      tooltipIsOpen: true,
    }))
    setHits?.(hits)
  }, [comments, setHits])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const uids = comments.map((x) => x.uid)
    markCommentNotificationsAsRead(uids)
  }, [comments])

  const handlePlay: (timestamp: number, commentUid: string) => void = (
    timestamp,
    commentUid,
  ) => {
    if (isPlaying && commentUid === playingTrackId) {
      pause()
    } else {
      play(timestamp, true)
      setPlayingTrackId(commentUid)
      analytics.event('comment_conversationDetails_playAudio', {
        timestamp,
      })
    }
  }

  const handleReply = (
    recipientUserUids: string[],
    timestamp: number,
  ): void => {
    analytics.event('comment_conversationDetails_reply')
    openCommentsModal({
      initialTime: timestamp,
      initialRecipientUserUids: recipientUserUids,
      conversationUid: conversation.uid,
      initialFocus: 'comment',
      onClose: () => mutate(),
    })
  }

  const handleForward = (message: string, timestamp: number): void => {
    analytics.event('comment_conversationDetails_forward')
    openCommentsModal({
      initialTime: timestamp,
      initialText: message,
      conversationUid: conversation.uid,
      initialFocus: 'recipients',
      onClose: () => mutate(),
    })
  }

  const handleNewComment = (): void => {
    openCommentsModal({
      conversationUid: conversation.uid,
      initialTime: getTime(),
      onClose: () => mutate(),
    })
  }

  const renderComment = (comment: Comment): ReactElement => {
    const {
      uid,
      when,
      text,
      created,
      createdByUserUid,
      recipientUserUids: userUids,
    } = comment

    const recipientSet = new Set([...userUids, createdByUserUid])
    recipientSet.delete(currentUser.id)
    const recipientUserUids = Array.from(recipientSet)
    const user = getUserByUid(createdByUserUid)

    const profileImageUrl =
      generateImageUrl(user.profileImage?.key, { size: 32 }) || undefined

    const recipients = recipientUserUids.map(getUserByUid)

    const isTrackPlaying = isPlaying && uid === playingTrackId

    const formattedDate = i18n.date(created, TooltipDateFormatOptions)
    return (
      <Flex
        flexDir="column"
        key={uid}
        border="1px"
        borderColor="gray.200"
        borderRadius="4"
        w="100%"
      >
        <VStack p="4" w="100%" align="start">
          <HStack align="start" gap="2" w="100%">
            <Avatar size="xs" src={profileImageUrl} name={user.name} />
            <VStack w="100%" align="flex-start" gap="0">
              <Flex align="baseline" justify="space-between">
                <Text fontWeight="medium">
                  {currentUser.id === user.uid ? t`You` : user.name}
                </Text>
              </Flex>
              <HStack w="100%" justify="space-between">
                <Text>
                  <Trans>To</Trans>{' '}
                  <Join
                    values={recipients}
                    separator={(isLast) => (isLast ? ` ${t`and`} ` : ', ')}
                  >
                    {(user) =>
                      user.uid === currentUser.id ? t`you` : user.name
                    }
                  </Join>
                </Text>
                <Tooltip
                  hasArrow
                  label={formattedDate}
                  aria-label={formattedDate}
                >
                  <Box display="inline" color="textMuted" fontSize="xs" ml={2}>
                    <RelativeTimeFormat date={created} />
                  </Box>
                </Tooltip>
              </HStack>
            </VStack>
          </HStack>
          <Divider color="gray.400" />
          <Text
            mt={2}
            whiteSpace="pre-wrap"
            // overflow-wrap: anywhere is not supported by Safari there we add the deprecated word-break: break-word as well
            wordBreak="break-word"
            overflowWrap="anywhere"
          >
            {text}
          </Text>
        </VStack>
        <Divider />
        <Box
          width="100%"
          p="3"
          pt="0"
          pb="2"
          bg="accents.lightBackground.default"
        >
          <Flex align="center" mt={2} justify="space-between">
            <Flex align="center">
              {isPlayable && (
                <PlayButton
                  isDisabled={!currentUser.permissions.playback}
                  onClick={() => handlePlay(when, uid)}
                  isPlaying={isTrackPlaying}
                  size="xs"
                  mr={2}
                />
              )}
              <Text color="gray.600" fontSize="sm">
                {formatTime(when)}
              </Text>
            </Flex>
            <HStack gap="1">
              <Button
                variant="ghost"
                size="xs"
                onClick={() => handleReply(recipientUserUids, when)}
                leftIcon={<MdReply />}
              >
                <Trans>Reply</Trans>
              </Button>
              <Button
                variant="ghost"
                size="xs"
                onClick={() => handleForward(text, when)}
                leftIcon={<MdForward />}
              >
                <Trans>Forward</Trans>
              </Button>
            </HStack>
          </Flex>
        </Box>
      </Flex>
    )
  }

  return (
    <VStack flex="1 0 50%" align="left" gap="2">
      <HStack justify="space-between" h="6">
        <Text fontWeight="medium">
          <Trans>Comments</Trans>
        </Text>
        {currentUser.permissions.playback && (
          <AddCommentButton size="xs" pl={3} onClick={handleNewComment} />
        )}
      </HStack>
      {comments.length === 0 ? (
        <Text color="gray.600">
          <Trans>No comments! 🤐</Trans>
        </Text>
      ) : (
        <>
          <VStack w="100%" gap="4">
            {comments.map(renderComment)}
          </VStack>
        </>
      )}
    </VStack>
  )
}

export default CommentsView
