import { ConversationResponseModel } from '@capturi/api-conversations'
import { generateImageUrl } from '@capturi/request'
import { useOrganization, useUsers } from '@capturi/stores'
import { Avatar, HStack, Text, VStack } from '@chakra-ui/react'
import { Trans, select } from '@lingui/macro'
import React from 'react'

type StatisticsView = {
  conversation: ConversationResponseModel
  agentName?: string
}

const StatisticsView: React.FC<StatisticsView> = ({ conversation }) => {
  const { organizationType } = useOrganization()
  const { insights } = conversation

  const { getUserByUid } = useUsers()

  return (
    <VStack align="left" flex="1 0 30%" gap="2">
      <Text fontWeight="medium" h="6">
        <Trans>Statistics</Trans>
      </Text>
      <HStack
        border="1px"
        borderColor="gray.200"
        borderRadius="4px"
        gap="3"
        p="3"
        align="flex-start"
      >
        <Avatar
          size="sm"
          src={generateImageUrl(
            getUserByUid(conversation.userUid).profileImage?.key,
            { size: 32 },
          )}
          name={getUserByUid(conversation.userUid).name}
        />
        <VStack align="flex-start" py="1" gap="3" w="100%">
          <Text fontSize="lg" fontWeight="medium">
            {getUserByUid(conversation.userUid).name.split(' ')[0]}
          </Text>
          <HStack justify="space-between" w="100%">
            <VStack align="flex-start" gap="0">
              <HStack flexShrink="0" gap="1" align="baseline">
                <Text fontSize="lg" fontWeight="medium">
                  {insights?.salesPerson?.wordsPerMinute.toFixed()}
                </Text>
                <Text fontSize="xs" color="gray.800">
                  <Trans>words/minute</Trans>
                </Text>
              </HStack>
              <Text fontSize="sm" color="gray.600">
                <Trans>Speech rate</Trans>
              </Text>
            </VStack>
            <VStack align="flex-start" gap="0">
              <HStack flexShrink="0" gap="1" align="baseline">
                <Text fontSize="lg" fontWeight="medium">
                  {insights?.salesPerson?.longestMonologue?.duration.toFixed()}
                </Text>
                <Text fontSize="xs" color="gray.800">
                  <Trans>seconds</Trans>
                </Text>
              </HStack>
              <Text fontSize="sm" color="gray.600">
                <Trans>Longest monologue</Trans>
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </HStack>

      <HStack
        border="1px"
        borderColor="gray.200"
        borderRadius="4px"
        gap="4"
        p="3"
        align="flex-start"
      >
        <Avatar size="sm" />
        <VStack align="flex-start" py="1" gap="3" w="100%">
          <Text fontSize="lg" fontWeight="medium">
            {select(organizationType, {
              public: 'Citizen',
              other: 'Customer',
            })}
          </Text>
          <HStack justify="space-between" w="100%">
            <VStack align="flex-start" gap="0">
              <HStack flexShrink="0" gap="1" align="baseline">
                <Text fontSize="lg" fontWeight="medium">
                  {insights?.otherPerson?.wordsPerMinute.toFixed()}
                </Text>
                <Text fontSize="xs" color="gray.800">
                  <Trans>words/minute</Trans>
                </Text>
              </HStack>
              <Text fontSize="sm" color="gray.600">
                <Trans>Speech rate</Trans>
              </Text>
            </VStack>
            <VStack align="flex-start" gap="0">
              <HStack flexShrink="0" gap="1" align="baseline">
                <Text fontSize="lg" fontWeight="medium">
                  {insights?.otherPerson?.longestMonologue?.duration.toFixed()}
                </Text>
                <Text fontSize="xs" color="gray.800">
                  <Trans>seconds</Trans>
                </Text>
              </HStack>
              <Text fontSize="sm" color="gray.600">
                <Trans>Longest monologue</Trans>
              </Text>
            </VStack>
          </HStack>
        </VStack>
      </HStack>
    </VStack>
  )
}

export default StatisticsView
